@font-face {
  font-family: 'NunitoSans-Black';
  src: local('NunitoSans-Black'),
    url(../assets/fonts/NunitoSans-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-BlackItalic';
  src: local('NunitoSans-BlackItalic'),
    url(../assets/fonts/NunitoSans-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Bold';
  src: local('NunitoSans-Bold'),
    url(../assets/fonts/NunitoSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-BoldItalic';
  src: local('NunitoSans-BoldItalic'),
    url(../assets/fonts/NunitoSans-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-ExtraBold';
  src: local('NunitoSans-ExtraBold'),
    url(../assets/fonts/NunitoSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-ExtraBoldItalic';
  src: local('NunitoSans-ExtraBoldItalic'),
    url(../assets/fonts/NunitoSans-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-ExtraLight';
  src: local('NunitoSans-ExtraLight'),
    url(../assets/fonts/NunitoSans-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-ExtraLightItalic';
  src: local('NunitoSans-ExtraLightItalic'),
    url(../assets/fonts/NunitoSans-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Italic';
  src: local('NunitoSans-Italic'),
    url(../assets/fonts/NunitoSans-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Light';
  src: local('NunitoSans-Light'),
    url(../assets/fonts/NunitoSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-LightItalic';
  src: local('NunitoSans-LightItalic'),
    url(../assets/fonts/NunitoSans-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-Regular';
  src: local('NunitoSans-Regular'),
    url(../assets/fonts/NunitoSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-SemiBold';
  src: local('NunitoSans-SemiBold'),
    url(../assets/fonts/NunitoSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'NunitoSans-SemiBoldItalic';
  src: local('NunitoSans-SemiBoldItalic'),
    url(../assets/fonts/NunitoSans-SemiBoldItalic.ttf) format('truetype');
}
