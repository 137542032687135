.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 100%;
  max-width: 100%;
  height: 0;
}

.video-react {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  font-size: 10px;
  line-height: 1;
  font-family: serif, Times, 'Times New Roman';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.video-react .video-react-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-react .video-react-big-play-button.big-play-button-hide {
  display: none;
}

.video-react-big-play-button {
  font-size: 0;
  line-height: 0;
  height: 0;
  width: 0;
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
}

.video-react .video-react-button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  overflow: visible;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.video-react-icon,
.video-react .video-react-big-play-button,
.video-react .video-react-play-progress,
.video-react .video-react-play-control,
.video-react .video-react-mute-control,
.video-react .video-react-volume-menu-button,
.video-react .video-react-volume-level,
.video-react .video-react-bezel .video-react-bezel-icon,
.video-react .video-react-closed-caption {
  font-family: 'video-react' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.video-react *,
.video-react *:before,
.video-react *:after {
  box-sizing: inherit;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button {
  border-radius: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
